import { React, useState } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { customPost } from "../../../../utils/services";
import WithPermission from "../../../../app/WithPermission";
import Button from "../../../../components/common/Button";
import * as Yup from "yup";
import toast from "react-hot-toast";
import Input from "../../../../components/common/Input";
import Select from "../../../../components/common/Select";

const {
  REACT_APP_API_URL
} = process.env;

const validaciones = Yup.object().shape({
  efectivo: Yup.number(),
  tarjeta: Yup.number(),
  deposito: Yup.number(),
  visa_link: Yup.number(),
  cheque: Yup.number(),
  credito: Yup.number(),
  user_id: Yup.number()
    .required("Usuario es requerido")
    .moreThan(0, 'Seleccione un usuario')
});

const thClass = "px-3 py-3.5 text-left text-sm font-semibold text-gray-900";
const tdClass = "whitespace-nowrap px-3 py-4 text-sm text-gray-500";

export const MovimientosRestaurante = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [movimientosActuales, setMovimientosActuales] = useState([]);
  const [realizarIngreso, setRealizarIngreso] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [usuarioFiltro, setUsuarioFiltro] = useState(0);

  const getMovimientos = () => {
    customPost({}, `cortes/movimientos/restaurante`, token).then(
      (data) => {
        setMovimientosActuales(data.data);
      }
    );

    customPost({}, `catalogos/usuarios`, token).then(
      (data) => {
        setUsuarios(data.data);
      }
    );
  };

  const handleChangeSelect = (e) => {
    setUsuarioFiltro(e.target.value);
    customPost({ user_id: e.target.value }, `cortes/movimientos/restaurante`, token).then(
      (data) => {
        setMovimientosActuales(data.data);
      }
    );
  };

  useState(() => {
    getMovimientos();
  }, [])

  return (
    <Formik
      initialValues={{
        efectivo: 0,
        deposito: 0,
        tarjeta: 0,
        visa_link: 0,
        credito: 0,
        cheque: 0,
        caja_inicial: 0,
        observaciones: "",
        user_id: 0
      }} validationSchema={validaciones}
      onSubmit={async (values, { resetForm }) => {

        setRealizarIngreso(false);
        await customPost(values, 'cortes/movimientos/restaurante/crear', token)
          .then(async (data) => {
            if (data?.status >= 200 && data?.status <= 210) {
              toast.success(data?.data?.msg);
              window.open(`${REACT_APP_API_URL}/imprimir-corte/${data?.data?.data?.id}`, "_blank")
              resetForm({});
              getMovimientos();
            }
          })
          .catch(function (error) {
            if (error.response.status >= 400 && error.response.status <= 450) {
              toast.error(error.response.data.error);
              setRealizarIngreso(true);
            } else if (error.response.status >= 500) {
              toast.error("Error interno comuniquese con el administrador");
            }
          });
      }}
    >
      {({ values, handleChange, handleSubmit }) => (
        <div className="mt-3 mx-2 xl:mx-4 lg:mx-4 justify-center flex">
          <div className="border rounded-lg hover:shadow-md p-4" style={{ width: `${realizarIngreso ? "700px" : "500px"}` }}>
            <div className="flex flex-row justify-center items-center text-primaryFont">
              {realizarIngreso ? <span className="">REALIZANDO CORTE</span> : <span className="">MOVIMIENTOS ACTUALES</span>}

            </div>

            <WithPermission permiso={`restaurante-ingreso-corte`}>
              <Select
                name="user_id"
                value={values.user_id}
                onChange={(e) => { handleChangeSelect(e); handleChange(e); }}
                validacion={true}
                label=""
                valueInit=""
                selectInit="seleccionar usuario"
                list={
                  usuarios?.map((item) => {
                    return (
                      <option value={item.id} key={item.name}>{item.name}</option>
                    );
                  })
                }
              />
            </WithPermission>
            <hr className="mb-3" />
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <WithPermission permiso={`restaurante-ingreso-corte`}>
                <table class="min-w-full divide-y divide-gray-300">

                  {realizarIngreso ? "" :
                    <tr>
                      <th></th>
                      <th className={thClass}>Ventas</th>
                      <th className={thClass}>Caja Inicial</th>
                      <th className={thClass}>Gastos</th>
                      <th className={thClass}>Esperado</th>
                    </tr>}
                  {realizarIngreso ? <tr>
                    <th></th>
                    <th className={thClass}>Esperado</th>
                    <th className={thClass}>Real</th>
                    <th className={thClass}>Diferencia</th>
                  </tr> : ""}
                  <tr>
                    <td className={thClass}>Efectivo</td>
                    {!realizarIngreso && <>
                      <td className={tdClass}> {parseFloat(movimientosActuales.efectivo).toFixed(2)} </td>
                      <td className={tdClass}> {parseFloat(movimientosActuales.caja_inicial).toFixed(2)} </td>
                      <td className={tdClass}> {parseFloat(movimientosActuales.efectivo_gastos).toFixed(2)} </td></>}
                    <td className={tdClass}>{(parseFloat(movimientosActuales.efectivo) + parseFloat(movimientosActuales.caja_inicial) - parseFloat(movimientosActuales.efectivo_gastos)).toFixed(2)}</td>
                    {realizarIngreso ? <td className={tdClass}>
                      <Input
                        type="number"
                        name="efectivo"
                        value={values.efectivo}
                        onChange={handleChange}
                        validacion={true}
                        label=""
                      />
                    </td> : ''}
                    {realizarIngreso ? <td className={tdClass}>
                      {((parseFloat(movimientosActuales.efectivo) + parseFloat(movimientosActuales.caja_inicial) - parseFloat(movimientosActuales.efectivo_gastos)) - values.efectivo).toFixed(2)}
                    </td> : ''}
                  </tr>
                  <tr>
                    <td className={thClass}>Deposito</td>
                    {!realizarIngreso && <>
                      <td className={tdClass}>{parseFloat(movimientosActuales.deposito).toFixed(2)}</td>
                      <td className={tdClass}> - </td>
                      <td className={tdClass}> {parseFloat(movimientosActuales.deposito_gastos).toFixed(2)} </td></>}

                    <td className={tdClass}>{parseFloat(movimientosActuales.deposito).toFixed(2)}</td>
                    {realizarIngreso ? <td className={tdClass}>
                      <Input
                        type="number"
                        name="deposito"
                        value={values.deposito}
                        onChange={handleChange}
                        validacion={true}
                        label=""
                      />
                    </td> : ''}
                    {realizarIngreso ? <td className={tdClass}>
                      {(movimientosActuales.deposito - values.deposito).toFixed(2)}
                    </td> : ''}
                  </tr>
                  <tr>
                    <td className={thClass}>Tarjeta</td>
                    {!realizarIngreso && <>
                      <td className={tdClass}>{parseFloat(movimientosActuales.tarjeta).toFixed(2)}</td>
                      <td className={tdClass}> - </td>
                      <td className={tdClass}> {parseFloat(movimientosActuales.tarjeta_gastos).toFixed(2)} </td></>}
                    <td className={tdClass}>{parseFloat(movimientosActuales.tarjeta).toFixed(2)}</td>
                    {realizarIngreso ? <td className={tdClass}>
                      <Input
                        type="number"
                        name="tarjeta"
                        value={values.tarjeta}
                        onChange={handleChange}
                        validacion={true}
                        label=""
                      />
                    </td> : ''}
                    {realizarIngreso ? <td className={tdClass}>
                      {(movimientosActuales.tarjeta - values.tarjeta).toFixed(2)}
                    </td> : ''}
                  </tr>
                  <tr>
                    <td className={thClass}>Visa Link</td>
                    {!realizarIngreso && <>
                      <td className={tdClass}>{parseFloat(movimientosActuales.visa_link).toFixed(2)}</td>
                      <td className={tdClass}> - </td>
                      <td className={tdClass}> - </td></>}
                    <td className={tdClass}>{parseFloat(movimientosActuales.visa_link).toFixed(2)}</td>
                    {realizarIngreso ? <td className={tdClass}>
                      <Input
                        type="number"
                        name="visa_link"
                        value={values.visa_link}
                        onChange={handleChange}
                        validacion={true}
                        label=""
                      />
                    </td> : ''}

                    {realizarIngreso ? <td className={tdClass}>
                      {(movimientosActuales.visa_link - values.visa_link).toFixed(2)}
                    </td> : ''}
                  </tr>

                  <tr>
                    <td className={thClass}>Credito</td>
                    {!realizarIngreso && <>
                      <td className={tdClass}>{parseFloat(movimientosActuales.credito).toFixed(2)}</td>
                      <td className={tdClass}> - </td>
                      <td className={tdClass}> {parseFloat(movimientosActuales.credito_gastos).toFixed(2)}</td></>}
                    <td className={tdClass}>{parseFloat(movimientosActuales.credito).toFixed(2)}</td>
                    {realizarIngreso ? <td className={tdClass}>
                      <Input
                        type="number"
                        name="credito"
                        value={values.credito}
                        onChange={handleChange}
                        validacion={true}
                        label=""
                      />
                    </td> : ''}

                    {realizarIngreso ? <td className={tdClass}>
                      {(movimientosActuales.credito - values.credito).toFixed(2)}
                    </td> : ''}
                  </tr>

                  <tr>
                    <td className={thClass}>Cheque</td>
                    {!realizarIngreso && <>
                      <td className={tdClass}>{parseFloat(movimientosActuales.cheque).toFixed(2)}</td>
                      <td className={tdClass}> - </td>
                      <td className={tdClass}> {parseFloat(movimientosActuales.cheque_gastos).toFixed(2)}</td></>}
                    <td className={tdClass}>{parseFloat(movimientosActuales.cheque).toFixed(2)}</td>
                    {realizarIngreso ? <td className={tdClass}>
                      <Input
                        type="number"
                        name="cheque"
                        value={values.cheque}
                        onChange={handleChange}
                        validacion={true}
                        label=""
                      />
                    </td> : ''}

                    {realizarIngreso ? <td className={tdClass}>
                      {(movimientosActuales.cheque - values.cheque).toFixed(2)}
                    </td> : ''}
                  </tr>
                </table>
              </WithPermission>

              {realizarIngreso ?
                <div className="px-5 mt-5 mb-5">
                  <div className="px-5 mt-5 mb-5">
                    <Input
                      type="text"
                      name="observaciones"
                      value={values.observaciones}
                      onChange={handleChange}
                      validacion={true}
                      label="Observaciones"
                    />
                  </div>

                  <WithPermission permiso={`restaurante-ingreso-corte`}>
                    <Button text="Guardar Corte" onClick={handleSubmit} type="submit" />
                  </WithPermission>
                </div>
                :
                <div className="px-5 mt-5 mb-5">
                  <WithPermission permiso={`restaurante-ingreso-corte`}>
                    <Button text="Realizar Corte" onClick={() => { setRealizarIngreso(true) }} />
                  </WithPermission>
                </div>}

            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
